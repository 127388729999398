import React, { FunctionComponent, Fragment } from "react";
import * as yup from "yup";
import { translate } from "utils/i18n";

import { StyledInput } from "./text.styles";
import FormikLock from "../lock";
import FormikError, { parseError } from "../error";

import FormikField, { setValue } from "../field";
import { FieldTypeEnum } from "redux/models/field-type";
import { SubmitOnEnterOpts, FieldModel } from "../utils";

export const formikUrlValidate = (url: string) => {
  const schema = yup.string().url();
  if (!schema.isValidSync(url)) {
    return translate("formik.url.invalid");
  }
  return undefined;
};

export interface UrlOptions extends SubmitOnEnterOpts {}

const UrlField: FunctionComponent<FieldModel> = ({
  name,
  label,
  guide,
  required,
  placeholder,
  disabled = false,
  urlOptions = {},
  onChange = () => {},
  onKeyDown = () => {},
}) => {
  const { submitOnEnter = false } = urlOptions;

  return (
    <FormikField name={name} type={FieldTypeEnum.URL} label={label} guide={guide} required={required}>
      {({ field, form }) => {
        const fnOnChange = (event: any) => {
          const { target } = event;

          // get value and lowercase it
          const value: string = target.value || "";
          const lowercasedValue = value.toLowerCase();

          // save value
          setValue(form, field.name, lowercasedValue);
          onChange(lowercasedValue);
        };

        // wrap onkeydown
        const fnOnKeyDown = (event: any) => {
          if (submitOnEnter && event.key === "Enter") {
            form.submitForm();
          }
          onKeyDown(event, form);
        };

        const error = parseError(form, field);

        return (
          <Fragment>
            {/* Normal input element, with some extra juicy props */}
            <StyledInput
              error={error}
              autoComplete="off"
              maxLength={255}
              type="text"
              id={field.name}
              name={field.name}
              value={field.value}
              disabled={disabled}
              placeholder={placeholder}
              onChange={fnOnChange}
              onKeyDown={fnOnKeyDown}
            />

            <FormikLock disabled={disabled} />

            <FormikError error={error} />
          </Fragment>
        );
      }}
    </FormikField>
  );
};

export default UrlField;
