import React, { FunctionComponent } from "react";

import { StyledFormikGuide } from "./guide.styles";
import { nl2br } from "utils/string";

interface FormikGuideProps {
  className?: string;
  guide?: string;
}
const FormikGuide: FunctionComponent<FormikGuideProps> = ({ className, guide }) => {
  if (!guide) {
    return null;
  }

  return <StyledFormikGuide className={className}>{nl2br(guide)}</StyledFormikGuide>;
};

export default FormikGuide;
